.u-divider {
	border: none;
	border-bottom: 1px $c-border solid;
	width: 100%;
}

.u-divider--margin-top {
	margin-top: 3rem;
}

.u-divider--accent {
	max-width: 12rem;
	border-bottom: 3px $c-secondary solid;
}