.hero {
  position: relative;
  text-align: center;
  height: 100vh;

  @include respond-to(medium) {
    height: 35rem;
  }

  &--medium {
    height: 60rem;

    @include respond-to(medium) {
      height: 25rem;
    }
  }

  &--small {
    height: 35rem;

    @include respond-to(medium) {
      height: 17.5rem;
    }
  }

  &__background {
    opacity: .6;
    @extend %bg-cover;

    &-wrapper {
      position: absolute;
      background: black;
      z-index: 1;
      @extend %abs-full;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;
  }
}

@import "hero-intro";
@import "hero-featured";
@import "hero-arrow";
@import "hero-share";
@import "hero-stats";
