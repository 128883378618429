.projects {
  &__content {
    text-align: center;
  }

  &__title {
    margin: 0 0 3rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }

  &__subtitle {
    font-weight: 700;
    margin: 0 0 1.5rem 0;

    @include respond-to(small) {
      margin: 0 0 1rem 0;
    }
  }

  &__typography {
    margin: 0 0 4rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }
}
