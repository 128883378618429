.gallery {
  display: flex;

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $c-grey-light-1;
    min-width: 10rem;
    margin: 3rem 3rem 3rem 0;

    .gallery--large & {
      background: transparent;
      width: auto;
      margin: 0;
    }
  }

  &__control {
    background: $c-white;
    font-size: 1.6rem;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    border: none;
    border-radius: 5rem;
    text-align: center;
    cursor: pointer;
    margin: 0 0 1rem 0;
    @extend %anim2;

    &:last-of-type {
      margin: 0;
    }

    &:hover,
    &:focus {
      background: $c-primary;
      color: white;
      transform: scale(1.1);
    }
  }



  &__image {
    height: 25rem;
  }

  &__cta {
    height: inherit;

    img {
      width: auto;
      height: inherit;
    }
  }
}

.gallery--large {
  position: absolute;
  align-items: center;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;

  @include respond-to(small) {
    position: static;
    width: 100%;
    margin: 4rem 0 0 0;
  }

  .gallery__controls {
    min-width: 0;
    margin: 0;
  }

  .gallery__control--left,
  .gallery__control--right {
    position: absolute;
    margin: 0;
    z-index: 5;
  }

  .gallery__control--left {
    left: 2rem;
  }

  .gallery__control--right {
    right: 2rem;
  }

  .gallery__images {
    position: relative;
    padding: 4rem 0;

    @include respond-to(small) {
      padding: 0;
    }

    &:before {
      position: absolute;
      background: $c-grey-light-1;
      content: '';
      top: 0;
      right: 4rem;
      bottom: 0;
      left: -100rem;

      @include respond-to(small) {
        display: none;
      }
    }
  }

  .gallery__image {
    height: 35rem;

    @include respond-to(small) {
      height: 25rem;
    }
  }
}

#swipebox-close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none !important;
  @extend .qub;
  @extend .qub-cross;
  font-size: 1.5rem;
  color: white;
  right: 0 !important;
  @extend %anim1;
  &:hover, 
  &:focus {
    color: $c-primary;
  }
}

#swipebox-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none !important;
  @extend .qub;
  @extend .qub-caret-left;
  font-size: 1.5rem;
  color: white;
  right: 0 !important;
  @extend %anim1;
  &:hover, 
  &:focus {
    color: $c-black;
  }
}

#swipebox-next {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none !important;
  @extend .qub;
  @extend .qub-caret-right;
  box-shadow: none !important;
  font-size: 1.5rem;
  color: white;
  right: 0 !important;
  @extend %anim1;
  &:hover, 
  &:focus {
    color: $c-black;
  }
}

#swipebox-bottom-bar {
  text-shadow: none !important;
}