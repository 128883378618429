.quote__author {
  position: relative;

  &__image {
    position: absolute;
    left: -17rem;
    bottom: -1rem;
    @include respond-to(small) {
      left: -8rem;
      bottom: 3rem;
    }

    &::before {
      content: '';
      position: relative;
      top: -1rem;
      left: -1rem;
      display: block;
      width: 10rem;
      height: 10rem;
      border-radius: 5rem;
      background: $c-black;
      @include respond-to(small) {
        display: none;
      }
    }

    img {
      @extend %bg-cover;
      width: 14rem;
      height: 14rem;
      border-radius: 7rem;
      @include respond-to(small) {
        width: 7rem;
        height: 7rem;
      }
    }
  }

  &__name {
    color: $c-primary;
    font-size: 1.2rem;
  }
}
