.newsletter {
  &__content {
    text-align: center;
  }

  &__title {
    margin: 0 0 1rem 0;
  }

  &__subtitle {
    font-family: $f-tertiary;
    font-size: 0.9rem;
    font-weight: 900;
    color: $c-grey-1;
    line-height: 1.3rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin: 0 0 3rem 0;
  }
}

@import "newsletter-form";
