.full-image-teaser {
  position: relative;
  text-align: center;

  &__background {
    opacity: .6;
    @extend %bg-cover;

    &-wrapper {
      position: absolute;
      background: black;
      z-index: 1;
      @extend %abs-full;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__image {
    width: 15rem;
    margin: 0 auto 6rem auto;

    @include respond-to(small) {
      margin: 0 auto 3rem auto;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    color: $c-white;
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      margin: 0 0 1rem 0;
    }
  }

  &__typography {
    max-width: 50rem;
    margin: 0 auto 6rem auto;

    @include respond-to(small) {
      margin: 0 auto 3rem auto;
    }

    p {
      color: $c-white;
    }
  }
}
