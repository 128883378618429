.hero__share {
  position: absolute;
  background: $c-white;
  border-radius: 0.6rem 0 0 0.6rem;
  overflow: hidden;
  top: 30rem;
  right: 0;

  .hero--small & {
    top: 12rem;
  }

  @include respond-to(medium) {
    top: 2rem;
  }

  &--open {
    .hero__share__toggle {
      border-bottom: solid 0.1rem $c-grey-light-2;
    }

    .hero__share__list {
      display: block;
    }
  }

  &__toggle {
    background: transparent;
    width: 3.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    cursor: pointer;
    border: none;

    .qub-share { font-size: 1.8rem; }
  }

  &__list {
    display: none;
  }

  &__item {
    list-style: none;
  }

  &__cta {
    display: block;
    color: $c-black;
    text-decoration: none;
    width: 3.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    transition: all 0.2s ease-out;

    &:hover, &:focus {
      background: $c-primary;
      color: $c-white;
    }

    .qub-enveloppe { font-size: 1.1rem; }
    .qub-twitterline { font-size: 1.3rem; }
    .qub-facebookline { font-size: 1.6rem; }
    .qub-pinterestline { font-size: 1.6rem; }
  }
}
