.footer {
  background: $c-black;
  border-top: solid 0.3rem $c-primary;
}

@import "footer-info";
@import "footer-block";
@import "footer-pages";
@import "footer-social";
@import "footer-disclaimer";
