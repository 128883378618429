.text-teaser-block {
  text-align: center;

  &__title {
    margin: 0 0 2rem 0;
  }

  &__subtitle {
    margin: 0 0 3rem 0;
  }

  &__typography {
    max-width: 60rem;
    margin: 0 auto 4rem auto;
  }
}
