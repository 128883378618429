.hero__stats {
  position: absolute;
  display: flex;
  align-items: center;
  background: $c-black;
  padding: 2.2rem;
  bottom: 0;
  left: 0;

  @include respond-to(medium) {
    text-align: center;
    justify-content: space-around;
    padding: 1rem;
    right: 0;
  }

  &__number {
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 600;
    color: $c-white;
  }

  &__image {
    position: relative;
    margin: 0 1rem;

    @include respond-to(medium) {
      display: none;
    }

    &--house {
      width: 3.6rem;
      height: 3.6rem;
    }

    &--calendar {
      width: 2.4rem;
      height: 2.4rem;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:before {
      background: $c-white;
      top: 0;
      z-index: 1;
    }

    &:after {
      background: $c-primary;
      height: 0;
      z-index: 2;
      transition: height 1s ease-out;
      transition-delay: 1s;
    }

    img {
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }

  &__percentage {
    font-family: $f-tertiary;
    font-size: 1.4rem;
    font-weight: 600;
    color: $c-white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-right: 1.5rem;

    @include respond-to(medium) {
      font-size: 1rem;
      font-weight: 700;
    }

    span {
      color: $c-primary;
    }
  }

  &__date {
    font-family: $f-tertiary;
    font-size: 1.4rem;
    font-weight: 600;
    color: $c-white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;

    @include respond-to(medium) {
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
