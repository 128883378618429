.location {
  position: relative;

  &__map {
    position: relative;
    display: flex;
    background: $c-grey-light-2;
    margin-top: -8rem;
    width: calc(100% + 19rem);
    height: 35rem;

    @include respond-to(small) {
      position: relative;
      display: block;
      width: 100%;
      height: 20rem;
    }

    #map {
      position: absolute;
      @extend %abs-full;
    }
  }

  &__content {
    @include respond-to(small) {
      text-align: center;
    }
  }

  &__title {
    font-family: $f-tertiary;
    font-size: 1.1rem;
    font-weight: 800;
    color: $c-grey-1;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      margin: 0 0 1.5rem 0;
    }
  }

  &__address {
    font-family: $f-tertiary;
    font-size: 1.6rem;
    font-weight: 400;
    font-style: italic;
    color: $c-grey-2;
    line-height: 2.2rem;
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      margin: 0 0 0.5rem 0;
    }
  }

  &__cta {
    display: block;
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 400;
    font-style: italic;
    color: $c-grey-2;
    line-height: 2.2rem;
    margin: 0 0 4rem 0;
    &:hover, &:focus {
      text-decoration: none;
    }

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }

  &__destinations {

  }

  &__destination {
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-2;
    line-height: 2.2rem;
    list-style: none;
    margin: 0 0 1rem 0;

    &:last-child {
      margin: 0;
    }

    i {
      position: relative;
      font-size: 2.2rem;
      margin: 0 2rem 0 0;
      top: 0.6rem;
    }
  }
}
