.action {
  display: flex;
  position: relative;
  right: -3rem;

  @include respond-to(small) {
    display: none;
  }

  &__content {
    text-align: center;
  }

  &__typography {
    font-family: $f-tertiary;
    font-size: 1rem;
    font-weight: 600;
    color: $c-grey-1;
    margin: 0 0 1rem 0;
    letter-spacing: .02rem;
  }
  &__image {
    position: relative;
    width: 11rem;
    bottom: -3rem;
    margin-left: 2rem;

    @include respond-to(medium) {
      display: none;
    }

    img {
      position: absolute;
      width: 100%;
      bottom: 0rem;
    }
  }
}
