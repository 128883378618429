.teaser-block {
  position: relative;

  &--background {
    background: $c-grey-light-1;
  }

  // add margin bottom on mobile content or image depending on element order
  &.plain-full--right .image-teaser-block__content,
  &.plain-image--right .image-teaser-block__content,
  &.plain-full--left .image-teaser-block__image,
  &.plain-image--left .image-teaser-block__image {
    @include respond-to(small) {
      margin: 0 0 2.5rem 0;
    }
  }

  &__image {
    position: relative;
    box-shadow: 0px 0px 4rem 0px rgba($c-black, 0.2);

    @include respond-to(small) {
      max-width: 40rem;
      margin: 0 auto;
    }

    &--logo {
      margin: 0 0 6rem 0;

      @include respond-to(small) {
        margin: 0 auto;
      }
    }

    > img {
      position: relative;
      display: inherit;
      width: 100%;
      z-index: 1;
    }
  }

  &__logo {
    display: flex;
    position: absolute;
    background: $c-white;
    width: 15rem;
    height: 12rem;
    padding: 1rem;
    margin: 0 auto;
    box-shadow: 0px 0px 4rem 0px rgba($c-black, 0.2);
    right: 0;
    bottom: -6rem;
    left: 0;
    z-index: 1;

    @include respond-to(small) {
      position: static;
      box-shadow: none;
    }

    > img {
      display: inherit;
      width: 100%;
    }
  }

  &__content {
    margin: 4rem 0 0 0;

    @include respond-to(medium) {
      margin: 0;
    }
    @include respond-to(small) {
      display: block;
      max-width: 40rem;
      margin: auto;
      text-align: center;
    }
  }

  &__mark {
    margin: 0 0 4rem 0;

    img {
      width: 100%;
      max-width: 10rem;
    }
  }

  &__title {
    font-family: $f-primary;
    margin: 0 0 2rem 0;
  }

  &__subtitle {
    margin: 0 0 3rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }

  &__typography {
    + .teaser-block__button {
      margin-top: 4rem;

      @include respond-to(small) {
        margin-top: 2rem;
      }
    }
  }

  &__button {
    @include respond-to(small) {
      margin-bottom: 2rem;
    }
  }
}

@import "plain";
