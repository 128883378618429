.footer__block {
  border-left: solid 0.1rem rgba($c-white, 0.3);
  padding: 1rem 0 1rem 4rem;
  min-height: 10.5rem;

  @include respond-to(small) {
    text-align: center;
    border-left: none;
    padding: 0;
    margin: 0 0 4rem 0;
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include respond-to(small) {
      display: inherit;
      margin: 0;
    }
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.1rem;
    font-weight: 400;
    color: $c-white;
    line-height: 1.7rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      margin: 0 0 1rem 0;
    }
  }

  &__paragraph {
    font-family: $f-tertiary;
    font-size: 1.3rem;
    font-weight: 400;
    color: $c-white;
    opacity: 0.7;
    line-height: 2.5rem;

    a {
      color: inherit;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}
