.step-tile {
  &__number {
    display: block;
    font-family: $f-secondary;
    font-size: 6rem;
    font-weight: 700;
    color: $c-primary;
    margin: 0 0 2rem 0;

    @include respond-to(small) {
      font-size: 4.5rem;
    }
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.5rem;
    font-weight: 400;
    color: $c-black;
    line-height: 2.3rem;
    margin: 0 0 2rem 0;
  }

  &__paragraph {

  }
}
