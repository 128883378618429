.highlight-tiles {
  &__intro {
    text-align: center;
  }

  &__title {
    margin: 0 0 3rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }

  &__subtitle {
    margin: 0 0 5rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }
}

@import "highlight-tile";
