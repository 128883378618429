.advantage {
  &__title {
    margin: 0 0 3rem 0;
    font-size: 1.8rem;
    color: $c-white;
  }

  &__typography {
    margin: 0 auto;
    max-width: 30rem;
  }

  @include respond-to(small) {
    margin: 2rem 0;
  }
}
