.contact {
  position: relative;
  background: $c-grey-light-1;

  &--project {
    &:before {
      position: absolute;
      background: url('../img/Q.svg');
      background-repeat: no-repeat;
      background-position: center left;
      content: '';
      @extend %abs-full;
    }

    .contact__content,
    .contact-form {
      max-width: 40rem;
      margin: 0 auto;
    }

    .contact-form {
      margin-top: 6rem;

      @include respond-to(small) {
        margin-top: 4rem;
      }
    }
  }

  &__image {
    position: absolute;
    right: 12%;
    bottom: 0;

    @include respond-to(medium) {
      right: 2%;
    }

    @include respond-to(small) {
      display: none;
    }

    img {
      display: inherit;
    }
  }

  &__map {
    position: absolute;
    background: $c-grey-light-2;
    content: '';
    width: 50%;
    height: 100%;

    @include respond-to(small) {
      position: relative;
      width: 100%;
      height: auto;
      padding: 2rem;
      margin: 4rem 0 0 0;
    }

    #map {
      position: absolute;
      @extend %abs-full;
    }
  }
}

@import "contact-form";
