.partner {
  max-width: 20rem;
  padding: 0 2rem 3rem 2rem;

  @include respond-to(xsmall) {
  	max-width: 12rem;
  }
  
  &__logo {
    width: 100%;
  }
}
