.quote__control {
  &__button {
    position: absolute;
    top: calc(50% - 2.5rem);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    background: $c-white;
    cursor: pointer;
    @extend %anim1;

    &:hover,
    &:focus {
      background: $c-primary;
      color: white;
      transform: scale(1.1);
    }

    i {
      position: relative;
      top: .2rem;
    }

    &--left {
      left: 10rem;
    }

    &--right {
      right: 10rem;
    }
  }

  &__bars {
    margin: 5rem 0 0 0;
    text-align: center;
  }

  &__bar {
    display: inline-block;
    margin: 0 0.4rem;
    width: 4rem;
    height: 0.4rem;
    background: $c-white;
    cursor: pointer;

    &:hover, &:focus {
      background: $c-primary;
    }

    &--active {
      background: $c-black;
    }
  }

  @include respond-to(medium) {
    &__button {
      display: none;
    }
  }

  @include respond-to(small) {
    &__bars {
      margin: 0;
    }
  }
}
