.stat {
  position: relative;
  text-align: center;
  width: 20%;
  padding: 0 3rem;

  @include respond-to(small) {
    width: auto;
  }

  &:not(:last-of-type) {
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 2.5rem);
      right: 0;
      height: 5rem;
      background: $c-grey-light-1;
      width: .1rem;
      @include respond-to(small) {
        display: none;
      }
    }
  }

  &__number {
    font-family: $f-secondary;
    font-size: 6rem;
    font-weight: 700;
    color: $c-primary;
    margin: 0 0 1rem 0;
    line-height: 1;
  }

  &__info {
    font-family: $f-primary;
    font-size: 1.4rem;
    font-weight: 400;
    color: $c-grey-1;
    line-height: 2rem;
  }
}
