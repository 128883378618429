.contact-form {
  text-align: center;

  &__error {
    display: block;
    font-family: $f-tertiary;
    font-size: 1.1rem;
    font-weight: 400;
    font-style: italic;
    color: $c-error;
    margin: 0 0 2rem 0;
  }

  &__input,
  &__textarea {
    background: transparent;
    font-family: $f-tertiary;
    font-size: 1.3rem;
    font-weight: 400;
    color: $c-grey-1;
    width: 100%;
    border: solid 0.1rem $c-grey-1;
    box-shadow: none;
    margin: 0 0 2rem 0;
    transition: all 0.2s ease-out;

    &:focus {
      background: $c-white;
      border: solid 0.1rem $c-grey-2;
    }

    &--error {
      border: solid 1px $c-error;
      margin: 0 0 0.5rem 0;
    }

    &--space {
      margin: 0 0 6rem 0;

      @include respond-to(small) {
        margin: 0 0 2rem 0;
      }
    }
  }

  &__input {
    height: 5rem;
    border-radius: 5rem;
    padding: 0 3rem;
  }

  &__textarea {
    resize: none;
    min-height: 20rem;
    border-radius: 1.5rem;
    padding: 1.5rem 3rem;
  }

  &__submit {
    cursor: pointer;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-style: italic;
  }
}
