.datatable {
  table {
    min-width: 100%;
    border-collapse: collapse;
  }

  thead {
    background: rgba(239, 239, 239, 0.5);

    th {
      box-sizing: content-box;
      padding: 2.8rem 1rem;
      text-align: left;
      white-space: nowrap;
      font-family: $f-tertiary;
      font-weight: 400;
      text-transform: uppercase;
      color: $c-black;
      cursor: pointer;
      font-size: 1.1rem;
      letter-spacing: .02rem;

      &:first-of-type {
        padding-left: 4rem;
      }

      &:last-of-type {
        padding-right: 4rem;
      }

      small {
        font-size: 0.8rem;
        text-transform: none;
      }

      i {
        margin: 0 0 0 0.5rem;
        vertical-align: middle;
        font-size: 5px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(239, 239, 239, 0.5);
    }

    td {
      padding: 2rem 1rem;
      white-space: nowrap;
      font-family: $f-tertiary;
      font-weight: 400;
      color: $c-grey-1;
      font-size: 1.2rem;

      &:first-of-type {
        padding-left: 4rem;
      }

      &:last-of-type {
        padding-right: 4rem;
      }

      a {
        color: $c-grey-1;
        text-decoration: none;
      }

      .qub-download {
        color: black;
        &:hover, &:focus {
          color: $c-primary;
        }
      }
    }
    
  }
}
