.advantages {
  position: relative;

  &__background {
    opacity: 0.4;
    @extend %bg-cover;

    &-wrapper {
      position: absolute;
      background: $c-black;
      z-index: 1;
      @extend %abs-full;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }
}

@import "advantage";
