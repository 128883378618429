.hero__arrow {
  position: absolute;
  font-size: 2.5rem;
  color: $c-white;
  right: 0;
  bottom: 3rem;
  left: 0;
  animation: bounce 2s infinite;

  @include respond-to(medium) {
    display: none;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-2rem);}
  60% {transform: translateY(-1rem);}
}
