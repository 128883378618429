.popover {
  position: absolute;
  background: $c-white;
  width: 100%;
  max-width: 30rem;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 4rem 0px rgba($c-black, 0.2);
  padding: 5rem 3rem;
  top: 2rem;
  left: 2rem;

  @include respond-to(small) {
    position: relative;
    top: 0;
    left: 0;
  }

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid $c-white;
    margin: 0 auto;
    right: 0;
    bottom: -1rem;
    left: 0;
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.4rem;
    font-weight: 400;
    color: $c-primary;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 0 0 3rem 0;
  }

  &__typography {
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-2;
    line-height: 2.5rem;
    margin: 0 0 3rem 0;

    a {
      color: inherit;
    }
  }

  &__credentials {
    &__item {
      font-family: $f-tertiary;
      font-size: 1.2rem;
      font-weight: 400;
      color: $c-grey-2;
      line-height: 2.5rem;
      list-style: none;
      margin: 0 0 1rem 0;

      &:last-child {
        margin: 0;
      }

      a {
        color: inherit;
      }

      i {
        position: relative;
        font-size: 2rem;
        color: inherit;
        margin: 0 1rem 0 0;
        top: 0.5rem;
      }
    }
  }
}
