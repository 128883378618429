.project-tiles {
  width: 100%;

  &--masonry {
    column-count: 3;
    column-gap: 3rem;

    @include respond-to(small) {
      column-count: 1;
      column-gap: 0;
    }

    .project-tile {
      break-inside: avoid-column;
      margin: 0 0 3rem 0;

      @include respond-to(small) {
        margin: 0 0 2rem 0;
      }
    }
  }
}

@import "project-tile";
