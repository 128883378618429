.board {
  background: $c-white;
  text-align: center;
  border: solid 0.2rem $c-primary;
  max-width: 40rem;
  padding: 4rem 0;
  margin: 0 auto;

  @include respond-to(small) {
    margin: 2rem auto 0 auto;
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.1rem;
    font-weight: 400;
    color: $c-primary;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 0 0 2rem 0;
  }

  &__typography {
    max-width: 20rem;
    margin: 0 auto;
  }
}
