.stats {
  &__inner {
    display: flex;
    justify-content: space-between;
    border-top: solid 0.1rem $c-grey-light-1;
    border-bottom: solid 0.1rem $c-grey-light-1;
    padding: 3rem 0;

    @include respond-to(small) {
      display: block;
    }
  }
}

@import "stat";
