@import "iconfont/iconfont";

.u-pagetitle {
	font-family: $f-primary;
	font-size: 3.5rem;
	font-weight: 400;
	color: $c-black;
	line-height: 4rem;
	text-transform: uppercase;

	@include respond-to(medium) {
		font-size: 3rem;
		line-height: 3.8rem;
	}
	@include respond-to(small) {
		font-size: 2.5rem;
		line-height: 3.2rem;
	}

	&--no-tt {
		text-transform: none;
	}
}

.u-title {
	font-family: $f-primary;
	font-size: 3rem;
	font-weight: 400;
	line-height: 3.5rem;
	text-transform: initial;
	
	@include respond-to(medium) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
	@include respond-to(small) {
		font-size: 2rem;
		line-height: 2.8rem;
	}

	&--small {
		font-size: 2rem;
	}

	&--bold {
		font-weight: 700;
	}

	&--primary {
		color: $c-primary;
	}
}

.u-subtitle {
	font-family: $f-tertiary;
	font-size: 1.1rem;
	font-weight: 400;
	color: $c-grey-1;
	line-height: 2rem;
	letter-spacing: 0.15rem;
	text-transform: uppercase;
	font-weight: 800;

	&--primary {
		color: $c-primary;
	}
}

.u-typography {
	p {
		font-family: $f-tertiary;
		font-size: 1.2rem;
		font-weight: 400;
		color: $c-grey-1;
		line-height: 2.2rem;
		margin: 0 0 2rem 0;

		strong {
			font-weight: 600;
		}

		&:last-child {
			margin: 0;
		}
	}

	a {
		color: inherit;
		text-decoration: underline;
		transition: opacity 0.2s ease-out;

		&:hover, &:focus {
			opacity: 0.5;
		}
	}

	ul {
		> li {
			position: relative;
			font-family: $f-tertiary;
			font-size: 1.2rem;
			font-weight: 400;
			color: $c-grey-1;
			line-height: 2.2rem;
			list-style: none;
			padding: 0 0 0 2rem;
			margin: 0 0 2rem 0;

			&:last-child {
				margin: 0;
			}

			&:before {
				position: absolute;
				background: $c-primary;
				content: '';
				width: 0.6rem;
				height: 0.6rem;
				border-radius: 0.6rem;
				top: 0.8rem;
				left: 0;
			}

			a {
				color: $c-grey-2;
				text-decoration: none;
			}
		}
	}

	&--white {
		p {
			color: $c-white;
		}
	}

	&--pdf {
		ul {
			> li {
				padding: 0;

				&:before {
					display: none;
				}

				i {
					position: relative;
					font-size: 2.2rem;
					color: $c-primary;
					margin: 0 2rem 0 0;
					top: 0.4rem;
				}
			}
		}
	}
}
