.info {
  padding: 6rem 6rem 6rem 0;

  @include respond-to(small) {
    padding: 4rem 0;
    text-align: center;
  }

  &--background {
    position: relative;
    background: $c-grey-light-1;

    &:before,
    &:after {
      position: absolute;
      background: inherit;
      content: '';
      width: 100rem;
      top: 0;
      bottom: 0;
    }

    &:before { right: 100%; }
    &:after { left: 100%; }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 3rem 0;

    @include respond-to(small) {
      display: block;
    }
  }

  &__title {
    font-family: $f-primary;
  	font-size: 3rem;
  	font-weight: 400;
  	color: $c-black;
  	line-height: 3.5rem;
  }

  &__cta {
    font-family: $f-tertiary;
		font-size: 1.2rem;
		font-weight: 400;
		color: $c-grey-2;
		line-height: 2rem;
    font-style: italic;
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &__image {
    position: relative;
    height: calc(100% - 6rem);
    width: calc(100% + 19rem);

    @include respond-to(small) {
      margin: 4rem 0;
      height: 30rem;
      width: 100%;
    }

    &__inner {
      position: absolute;
      @extend %abs-full;

      @include respond-to(small) {
        position: static;
      }

      img {
        height: 100%;
        @extend %bg-cover;

        @include respond-to(small) {
          width: 100%;
        }
      }
    }
  }
}
