.nav__social {
  @include respond-to(small) {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    list-style: none;
    margin: 0 1.5rem 0 0;

    @include respond-to(medium) {
      margin: 0 1rem 0 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__cta {
    font-size: 1.3rem;
    color: $c-grey-2;
    text-decoration: none;
    transition: color 0.2s ease-out;

    .nav--float & {
      color: $c-white;
    }

    &:hover, &:focus {
      color: $c-primary;
    }
  }
}
