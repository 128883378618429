@font-face {
	font-family: "qub_iconfont";
	src: url('/fonts/iconfont/qub_iconfont.eot');
	src: url('/fonts/iconfont/qub_iconfont.eot?#iefix') format('eot'),
		url('/fonts/iconfont/qub_iconfont.woff') format('woff'),
		url('/fonts/iconfont/qub_iconfont.ttf') format('truetype'),
		url('/fonts/iconfont/qub_iconfont.svg#qub_iconfont') format('svg');
}

.qub:before {
	font-family: "qub_iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.qub-arrow-down:before {
	content: "\E001";
}

.qub-arrow-right:before {
	content: "\E002";
}

.qub-calendar:before {
	content: "\E003";
}

.qub-caret-left:before {
	content: "\E004";
}

.qub-caret-right:before {
	content: "\E005";
}

.qub-caret:before {
	content: "\E006";
}

.qub-cross:before {
	content: "\E007";
}

.qub-download:before {
	content: "\E008";
}

.qub-email:before {
	content: "\E009";
}

.qub-enveloppe:before {
	content: "\E00A";
}

.qub-facebook:before {
	content: "\E00B";
}

.qub-facebookline:before {
	content: "\E00C";
}

.qub-hamburger:before {
	content: "\E00D";
}

.qub-instagram:before {
	content: "\E00E";
}

.qub-marker:before {
	content: "\E00F";
}

.qub-pdf:before {
	content: "\E010";
}

.qub-phone:before {
	content: "\E011";
}

.qub-pinterest:before {
	content: "\E012";
}

.qub-pinterestline:before {
	content: "\E013";
}

.qub-plus:before {
	content: "\E014";
}

.qub-share:before {
	content: "\E015";
}

.qub-tgv:before {
	content: "\E016";
}

.qub-train:before {
	content: "\E017";
}

.qub-twitter:before {
	content: "\E018";
}

.qub-twitterline:before {
	content: "\E019";
}
