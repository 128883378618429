// plain-full
.teaser-block.plain-full {
  &:before {
    position: absolute;
    content: '';
    width: 40%;
    height: 100%;
    top: 0;
    bottom: 0;

    @include respond-to(small) {
      display: none;
    }
  }

  &--right:before { right: 0; }
  &--left:before { left: 0; }
  &--light:before { background: $c-grey-light-1; }
  &--dark:before { background: $c-black; }
}

// plain-image
.teaser-block.plain-image {
  .teaser-block__image {
    padding: 6rem 0 0 0;

    @include respond-to(small) {
      padding: 0;
    }

    &:before {
      position: absolute;
      content: '';
      width: 200rem;
      height: 80%;
      top: 0;

      @include respond-to(small) {
        display: none;
      }
    }
  }

  &--right .teaser-block__image:before { left: -6rem; }
  &--left .teaser-block__image:before { right: -6rem; }
  &--light .teaser-block__image:before { background: $c-grey-light-1; }
  &--dark .teaser-block__image:before { background: $c-black; }

}

// responsive: add margin bottom on mobile when image is defined as .plain-full--left or .plain-image--left
.teaser-block.plain-full--left .teaser-block__image,
.teaser-block.plain-image--left .teaser-block__image {
  @include respond-to(small){ margin: 0 auto 4rem; }
}
