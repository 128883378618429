.sidebar__languages {
  &__list {
    display: flex;
  }

  &__item {
    list-style: none;

    &:first-child {
      border-radius: 0.6rem 0 0 0.6rem;
    }

    &:last-child {
      border-radius: 0 0.6rem 0.6rem 0;
    }
  }

  &__cta {
    background: $c-grey-light-1;
    font-family: $f-primary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-1;
    border-radius: inherit;
    text-decoration: none;
    padding: 0.5rem 2rem;

    &--active {
      background: $c-primary;
      color: $c-white;
    }
  }
}
