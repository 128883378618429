.modal {
  display: none;
  position: absolute;
  z-index: 9999;
  @extend %abs-full;

  &--open {
    display: flex;
    padding: 8rem 0 0 0;
    justify-content: center;
    align-items: flex-start;
  }

  &__overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    @extend %abs-full;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    padding: 5rem 8rem 10rem 8rem;
    background: $c-white;

    @include respond-to(small) {
      padding: 5rem 4rem 10rem 4rem;
    }
  }

  &__close {
    position: absolute;
    background: none;
    border: none;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;

    i {
      font-size: 1.5rem;
      color: $c-grey-1;
    }
  }

  &__title {
    margin: 0 0 3rem 0;
    font-family: $f-primary;
  }

  &__typography {
    margin: 0 0 5rem 0;
    max-width: 25rem;
  }

  &__form {
    width: 40rem;

    @include respond-to(small) {
      width: 100%;
      max-width: 40rem;
    }
  }

  &__submit {
    margin: 2rem 0 0 0;
  }

  &__call-now {
    margin: 10rem 0 1rem 0;
    font-family: $f-tertiary;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    color: $c-grey-1;
    text-transform: uppercase;

    @include respond-to(small) {
      margin: 4rem 0 1rem 0;
    }
  }

  &__call-now-number {
    font-family: $f-tertiary;
    font-size: 1.7rem;
    font-weight: 800;
    letter-spacing: 0.2rem;
    color: $c-grey-2;
  }
}
