.hero__intro {
  width: 100%;
  max-width: 80rem;
  text-align: center;
  @include respond-to(small) {
    padding: 3rem 0;
  }
  &__title {
    font-family: $f-primary;
    font-size: 6rem;
    font-weight: 400;
    color: $c-white;
    margin: 1rem 0 3rem 0;

    @include respond-to(medium) {
      font-size: 4rem;
      margin: 0 0 0.5rem 0;
    }
    @include respond-to(small) {
      font-size: 3rem;
      margin: 0 0 0.5rem 0;
    }

    .hero--small & {
      font-size: 4rem;

      @include respond-to(medium) {
        font-size: 3rem;
      }
    }
  }

  &__typography {
    font-family: $f-secondary;
    font-size: 1.6rem;
    font-weight: 400;
    color: $c-white;
    max-width: 80rem;
    width: 90%;
    margin: 0 auto;

    @include respond-to(medium) {
      font-size: 1.5rem;
    }
  }

  &__location {
    font-family: $f-tertiary;
    font-size: 0.9rem;
    font-weight: 800;
    color: $c-white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin: 2rem 0 0 0;

    @include respond-to(medium) {
      margin: 1rem 0 0 0;
    }
  }
}
