.sidebar__social {
  &__list {
    display: flex;
  }

  &__item {
    list-style: none;
    margin: 0 1.5rem 0 0;

    &:last-child {
      margin: 0;
    }
  }

  &__cta {
    font-size: 1.5rem;
    color: $c-grey-2;
    text-decoration: none;
  }
}
