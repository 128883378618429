.nav__hamburger {
  display: none;
  background: transparent;
  font-size: 1.5rem;
  color: $c-black;
  border: none;

  @include respond-to(xsmall) {
    display: block;
  }

  .nav--float & {
    color: $c-white;
  }
}
