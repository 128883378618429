.nav {
  position: relative;
  background: $c-white;
  box-shadow: 0 0 4rem 0 rgba($c-black, 0.2);
  padding: 3rem 0;
  z-index: 999;

  @include respond-to(xsmall) {
    padding: 1rem 0;
  }

  &--float {
    position: absolute;
    background: transparent;
    box-shadow: none;
    padding: 0;
    top: 3rem;
    right: 0;
    left: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  &__logo {
    width: 15rem;
    @include respond-to(medium) {
      width: 12rem;
    }
    @include respond-to(small) {
      width: 8rem;
    }

    img {
      width: 100%;
    }
  }
}

@import "nav-pages";
@import "nav-extra";
@import "nav-languages";
@import "nav-social";
@import "nav-hamburger";
