.jumbotron {
  position: relative;
  background: $c-black;
  color: $c-white;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    width: 0; 
    height: 0; 
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid $c-black;
    margin: 0 auto;
    top: 100%;
    right: 0;
    left: 0;
  }

  &__title {
    display: block;
    font-size: 3rem;
    font-weight: 400;
    color: $c-white;
    margin: 0 0 1.5rem 0;
  }
  
  &__subtitle {
    
  }
}
