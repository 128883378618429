.highlight-tile {
  text-align: center;

  &__cta {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &__image {
    position: relative;
    background: $c-black;
    width: 100%;
    height: 40rem;
    margin: 0 0 5rem 0;

    @include respond-to(medium) {
      margin: 0 0 3rem 0;
    }

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }

    &:before {
      position: absolute;
      content: '';
      z-index: 1;
      transition: box-shadow 0.2s ease-out;
      box-shadow: 0 0 4rem 0 rgba($c-black, 0), 0 0 0 0 rgba($c-black, 1);
      @extend %abs-full;

      .highlight-tile__cta:hover &, .highlight-tile__cta:focus & {
        box-shadow: 0 0 4rem 0 rgba($c-black, 0.4), 0 0 0 0.7rem rgba($c-black, 1);
      }
    }

    img {
      @extend %bg-cover;
    }
  }

  &__icon {
    position: absolute;
    background: $c-white;
    width: 7rem;
    height: 7rem;
    border-radius: 7rem;
    line-height: 7rem;
    opacity: 0;
    margin: 0 auto;
    right: 0;
    bottom: -3rem;
    left: 0;
    z-index: 2;
    transform: scale(0.8);
    transition: all 0.2s ease-out;
    transition-delay: 0.1s;
    box-shadow: 0 1rem 4rem 0 rgba($c-black, 0.2);

    @include respond-to(medium) {
      bottom: -1.5rem;
    };

    @include respond-to(small) {
      bottom: 1.5rem;
    }

    .highlight-tile__cta:hover &, .highlight-tile__cta:focus & {
      opacity: 1;
      transform: scale(1);
    }

    i {
      font-size: 2.5rem;
    }
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.8rem;
    font-weight: 400;
    color: $c-black;
    line-height: 2.2rem;
    margin: 0 0 0.5rem 0;
  }

  &__category {
    display: block;
    font-family: $f-secondary;
    font-size: 1.6rem;
    font-weight: 400;
    color: $c-grey-1;
    line-height: 2rem;
    margin: 0 0 2rem 0;
  }

  &__typography {
    max-width: 90%;
    margin: 0 auto;
  }
}
