.tabs {
  &__list {
    display: flex;
    align-items: center;

    @include respond-to(small) {
      overflow-x: auto;
    }
  }

  &__item {
    list-style: none;
    margin: 0 4rem 0 0;

    @include respond-to(medium) {
      margin: 0 2rem 0 0;
    }

    @include respond-to(medium) {
      margin: 0 2rem 0 0;
    }

    &:nth-last-child(2) {
      margin: 0;

      @include respond-to(small) {
        margin: 0 2rem 0 0;
      }
    }

    &:last-child {
      display: none;

      @include respond-to(small) {
        display: block;
      }
    }
  }

  &__cta {
    font-family: $f-primary;
    font-size: 1.4rem;
    font-weight: 400;
    color: rgba($c-grey-2, .3);
    text-decoration: none;
    transition: color 0.2s ease-out;
    padding: 1rem 0;

    &:hover, &:focus {
      color: $c-grey-2;
    }

    &--active {
      position: relative;
      color: $c-grey-2;

      &:before {
        position: absolute;
        background: $c-grey-2;
        content: '';
        width: 100%;
        height: 0.2rem;
        right: 0;
        bottom: -4rem;
        left: 0;

        @include respond-to(small) {
          display: none;
        }
      }
    }
  }
}
