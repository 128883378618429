.partners {
  &__title {
    margin: 0 0 7rem 0;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
}

@import "partner";
