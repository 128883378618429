.chart {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    border: solid 0.1rem $c-grey-light-1;
    padding: 2rem 5rem;
    margin-top: 6rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    @include respond-to(small) {
      margin-top: 4rem;
    }
  }

  &__head {
    border-bottom: solid 0.1rem $c-grey-light-1;
    padding: 0 0 2rem 0;
    margin: 0 0 2rem 0;
  }

  &__foot {
    margin: 0 0 2rem 0;
  }

  &__number {
    position: relative;
    z-index: 1;
    top: 2rem;
    font-family: $f-secondary;
    font-size: 6rem;
    font-weight: 700;
    color: $c-grey-light-1;
    line-height: 2rem;
    margin: 0 0 2rem 0;
  }

  &__title {
    position: relative;
    z-index: 2;
    font-family: $f-primary;
    font-size: 1.8rem;
    font-weight: 400;
    color: $c-primary;
    line-height: 2rem;
  }

  &__info {
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-1;
    line-height: 2rem;
  }

  &__price {
    font-family: $f-secondary;
    font-size: 3rem;
    font-weight: 400;
    color: $c-black;
    line-height: 2rem;
  }
}
