.newsletter-form {
  display: flex;

  @include respond-to(xsmall) {
    display: initial;
  }

  &--center {
    justify-content: center;
  }

  &__input {
    font-family: $f-tertiary;
    font-size: 1.3rem;
    font-weight: 400;
    font-style: italic;
    color: $c-grey-1;
    width: 30rem;
    height: 5rem;
    border: solid 1px $c-grey-1;
    border-right: none;
    border-radius: 5rem 0 0 5rem;
    padding: 0 3rem;
    transition: border 0.2s ease-out;

    &:focus {
      border: solid 0.1rem $c-grey-2;
      border-right: none;
    }

    @include respond-to(xsmall) {
      width: 100%;
      text-align: center;
      border: solid 1px $c-grey-1;
      border-radius: 5rem;
      margin: 0 0 1rem 0;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: italic;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-style: italic;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-style: italic;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-style: italic;
  }

  &__submit {
    background: $c-primary;
    font-family: $f-tertiary;
    font-size: 1.1rem;
    font-weight: 700;
    color: $c-white;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    height: 5rem;
    border: none;
    border-radius: 0 5rem 5rem 0;
    cursor: pointer;
    padding: 0 2rem;
    transition: background 0.2s ease-out;

    &:hover, &:focus {
      background: darken($c-primary, 10%);
    }

    @include respond-to(xsmall) {
      width: 100%;
      border-radius: 5rem;
    }
  }
}
