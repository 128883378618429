// framed page
.container {
  max-width: $box-max-width;
  min-height: 100vh;
  margin:0 auto;
  background: $page-bg;
  box-shadow:0 0 3.5rem 0 rgba(0, 0, 0, 0.07);
  // padding: 0 1.1rem;
  overflow: hidden;
  
  @include respond-to(xlarge){
    max-width: $box-max-width;
  }
}

// normal page
.page {
  width: 100%;
  background-color: $page-bg;
}

.main-content {
  outline: none;
  position: relative;
}

@import "grid/settings";
@import "layout/layout";
@import "region/region";
