.footer__pages {
  &__list {
    display: flex;
    justify-content: flex-end;

    @include respond-to(small) {
      display: inherit;
      text-align: center;
    }
  }

  &__item {
    display: inline-block;
    font-family: $f-primary;
    font-size: 0.9rem;
    font-weight: 400;
    color: $c-white;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    list-style: none;
    margin: 0 0 0 2rem;

    @include respond-to(small) {
      display: block;
      margin: 1rem 0 0 0;
    }
  }

  &__cta {
    color: inherit;
    text-decoration: none;
    @extend %anim2;
    &:hover, &:focus {
      color: $c-primary;
    }
  }
}
