.project-tile {
  text-align: center;

  &--equal {
    .project-tile__image {
      overflow: hidden;
      height: 50rem;
      @include respond-to(medium) {
        max-width: 25rem;
        height: 30rem;
      }
      img {
        @extend %bg-cover;
      }
    }
  }

  &__cta {
    display: block;
    text-decoration: none;
  }

  &__image {
    position: relative;
    width: 100%;
    max-width: 40rem;
    margin: 0 auto 3rem auto;
    overflow: hidden;

    @include respond-to(small) {
      margin: 0 auto 1rem auto;
    }

    img {
      display: block;
      width: inherit;
      @extend %anim2;
      will-change: transform;
      .project-tile__cta:hover &, 
      .project-tile__cta:focus & {
        transform: scale(1.05);
      }
    }
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.8rem;
    font-weight: 400;
    color: $c-black;
    margin: 0 0 .5rem 0;
  }

  &__category {
    font-family: $f-secondary;
    font-size: 1.6rem;
    font-weight: 400;
    color: $c-grey-1;
    margin: 0 0 2rem 0;
  }

  &__link {
    font-family: $f-tertiary;
    font-size: 1.3rem;
    font-weight: 900;
    color: $c-primary;
    letter-spacing: 1px;
    text-transform: uppercase;

    i {
      position: relative;
      font-size: 0.6rem;
      margin: 0 0 0 1rem;
      top: -2px;
      left: 0;
      @extend %anim2;
      .project-tile__cta:hover &, 
      .project-tile__cta:focus & {
        left: .5rem;
      }
    }
  }
}
