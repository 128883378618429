.nav__languages {
  position: relative;
  margin: 0 2rem 0 0;
  @include respond-to(small) {
    margin: 0;
  }
  @include respond-to(xsmall) {
    display: none;
  }

  &__current {
    span {
      position: relative;
      font-family: $f-primary;
      font-size: 1rem;
      font-weight: 400;
      color: $c-grey-2;
      cursor: pointer;
      padding: 0 1.5rem 0 0;

      .nav--float & {
        color: $c-white;
      }

      i {
        position: absolute;
        font-size: 6px;
        top: 0.4rem;
        right: 0;
      }
    }

    span:first-child {
      display: block;

      @include respond-to(small) {
        display: none;
      }
    }

    span:last-child {
      display: none;

      @include respond-to(small) {
        display: block;
      }
    }
  }

  &__list {
    position: absolute;
    display: none;
    box-shadow: 0 0 4rem 0 rgba($c-black, 0.2);
    top: 120%;
    right: 0;
    z-index: 999;

    &--open {
      display: block;
    }
  }

  &__item {
    list-style: none;
  }

  &__cta {
    display: block;
    background: $c-white;
    font-family: $f-primary;
    font-size: 1rem;
    font-weight: 400;
    color: $c-grey-2;
    text-decoration: none;
    padding: 1rem;
    transition: background 0.2s ease-out;

    &:hover:not(&--active) {
      background: $c-grey-light-2;
    }

    &--active {
      background: $c-primary;
      color: $c-white;
    }
  }
}
