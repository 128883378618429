.quote {
  position: relative;
  padding: 0 0 3rem 0;
  background: $c-grey-light-1;

  &__box {
    margin: 2rem auto;
    padding: 7rem 5rem 3rem 14rem;
    width: 90vw;
    max-width: 79rem;
    background: $c-white;
    @include respond-to(small) {
      padding: 3rem 3rem 3rem 6rem;
    }
  }

  &__typography {
    position: relative;
    max-width: 55rem;
    margin: 0 0 7.5rem 0;
    font-style: italic;

    @include respond-to(small) {
      margin: 0 0 1rem 0;
    }

    &::before {
      content: '"';
      position: absolute;
      top: -2.5rem;
      left: -4rem;
      opacity: 0.3;
      font-size: 10rem;
      color: $c-primary;
    }

    p {
      color: $c-black;
      font-size: 1.6rem;
      line-height: 2.7rem;
      @include respond-to(small) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  @include respond-to(small) {
    &__box {
      margin: 2rem auto;
    }
  }
}

@import "quote-author";
@import "quote-control";
