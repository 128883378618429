.nav__pages {
  @include respond-to(xsmall) {
    display: none;
  }

  &__list {
    display: flex;
  }

  &__item {
    font-family: $f-primary;
    font-size: 1rem;
    font-weight: 400;
    color: $c-grey-2;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    list-style: none;
    white-space: nowrap;
    margin: 0 3.2rem 0 0;

    .nav--float & {
      color: $c-white;
    }

    &:last-child {
      margin: 0;
    }

    @include respond-to(medium) {
      letter-spacing: 0;
      margin: 0 1rem 0 0;
    }

    @include respond-to(small) {
      margin: 0 .5rem 0 0;
      font-size: .9rem;
    }
  }

  &__cta {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease-out;
    padding: .6rem 0;

    &:hover,
    &--active {
      color: $c-primary;
    }
  }
}
