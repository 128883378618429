.team {
  &__intro {
    text-align: center;
  }

  &__title {
    margin: 0 0 5rem 0;

    @include respond-to(small) {
      margin: 0 0 2rem 0;
    }
  }
}

@import "team-member";
@import "team-placeholder";
