.filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 6rem 0;

  @include respond-to(small) {
    margin: 0;
  }

  &__item {
    font-family: $f-primary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-3;
    border: solid 0.1rem $c-grey-3;
    border-radius: 0.6rem;
    white-space: nowrap;
    text-decoration: none;
    padding: 1.2rem 4rem;
    margin: 0 0 2rem 2rem;
    transition: color 0.2s ease-out;

    @include respond-to(small) {
      padding: 1.2rem;
    }

    &:hover, 
    &:focus,
    &--active {
      color: $c-primary;
      border: solid 0.1rem $c-primary;
    }
  }
}
