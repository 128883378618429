.footer__social {
  &__list {
    display: flex;
    margin: 2rem 0 0 0;

    @include respond-to(small) {
      display: inherit;
      text-align: center;
    }
  }

  &__item {
    display: inline-block;
    list-style: none;
    &:not(:first-of-type) {
      margin: 0 0 0 3rem;
    }

    @include respond-to(small) {
      &:not(:first-of-type) {
        margin: 0 0 0 1.5rem;
      }

      &:first-child {
        margin: 0;
      }
    }
  }

  &__cta {
    font-size: 2rem;
    color: $c-white;
    text-decoration: none;
    @extend %anim2;
    &:hover, &:focus {
      color: $c-primary;
    }
  }
}
