.news-tile {
  background: $c-white;
  box-shadow: 0 0 4rem 0 rgba($c-black, 0.2);

  &__cta {
    display: block;
    text-decoration: none;
  }

  &__image {
    display: block;
    position: relative;
    width: 100%;
    height: 18.5rem;
    overflow: hidden;

    img {
      display: block;
      @extend %bg-cover;
      @extend %anim2;
      height: calc(100% + .2rem);
      .news-tile__cta:hover &,
      .news-tile__cta:focus & {
        transform: scale(1.05);
      }
    }
  }

  &__content {
    padding: 3rem 3rem 4rem 3rem;
  }

  &__type {
    display: block;
    margin: 0 0 1rem 0;
    font-family: $f-secondary;
    font-weight: 400;
    font-size: 1.6rem;
    color: $c-grey-1;
  }

  &__title {
    margin: 0 0 2rem 0;
    color: $c-black;
    @extend %anim2;
    .news-tile__cta:hover &,
    .news-tile__cta:focus & {
      color: $c-primary;
    }
  }

  &__excerpt {
    margin: 0 0 2rem 0;
    color: $c-grey-1;
  }

  &__readmore {
    font-family: $f-tertiary;
    font-weight: 800;
    font-size: 1.3rem;
    color: $c-primary;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    i {
      vertical-align: middle;
      margin: 0 0 0 1rem;
      font-size: 7px;
      @extend %anim2;
      position: relative;
      left: 0;
      .news-tile__cta:hover &,
      .news-tile__cta:focus & {
        left: .5rem; 
      }
    }
  }

  &--large {
    .news-tile__cta {
      display: flex;
      height: 100%;

      @include respond-to(small) {
        flex-direction: column;
      }
    }

    .news-tile__image {
      flex-basis: 50%;
      height: auto;

      @include respond-to(small) {
        height: 25rem;
      }
    }

    .news-tile__content {
      flex-basis: 50%;
      padding: 7rem 5rem 5rem 5rem;
    }

    .news-tile__title {
      margin: 0 0 3rem 0;
      font-size: 3rem;
    }

    .news-tile__excerpt {
      margin: 0 0 6rem 0;
    }
  }
}
