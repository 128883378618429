.button {
  display: inline-block;
  font-family: $f-tertiary;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  white-space: nowrap;
  @extend %anim2;
  box-shadow: 0 0 2rem rgba(0,0,0, 0);
  font-weight: 700;

  &:hover, 
  &:focus {
    box-shadow: 0 .5rem 3.5rem rgba(0,0,0, .1);
  }

  // 1. fill or line + color

  &--fill.button--primary {
    background: $c-primary;
    color: $c-white;
    border: solid 0.1rem $c-primary;

    &:hover, &:focus {
      lighten: darken($c-primary, 10%);
      border: solid 0.1rem lighten($c-primary, 10%);
    }
  }

  &--fill.button--white {
    background: $c-white;
    color: $c-grey-2;
    border: solid 0.1rem $c-white;

    &:hover, &:focus {
      color: $c-primary;
    }
  }

  &--line.button--primary {
    color: $c-primary;
    border: solid 0.1rem $c-primary;

    &:hover, &:focus {
      background: $c-primary;
      color: $c-white;
    }
  }

  &--line.button--white {
    color: $c-white;
    border: solid 0.1rem $c-white;

    &:hover, &:focus {
      background: $c-white;
      color: $c-black;
    }
  }

  // 2. small or large

  &--small {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 3rem;
    border-radius: 3rem;
    padding: 0 2.5rem;
  }

  &--large {
    font-size: 1.3rem;
    letter-spacing: 0.15rem;
    line-height: 4.5rem;
    border-radius: 4.5rem;
    padding: 0 4rem;
  }

  // 3. extra

  &--bold {
    font-weight: 700;
  }

  &--xbold {
    font-weight: 800;
  }
}
