.hero__featured {
  position: absolute;
  background: $c-primary;
  width: 30rem;
  bottom: -8rem;
  left: 3rem;
  box-shadow: 0 0 2rem rgba(0,0,0, .3);

  @include respond-to(medium) {
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__cta {
    text-decoration: none;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 14rem;

    @include respond-to(medium) {
      display: none;
    }

    img {
      @extend %bg-cover;
    }
  }

  &__content {
    padding: 2rem;

    @include respond-to(medium) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
    }
  }

  &__date,
  &__project {
    font-family: $f-primary;
    font-size: 2.4rem;
    font-weight: 400;
    color: $c-white;
  }

  &__title {
    font-family: $f-tertiary;
    font-size: 1.1rem;
    font-weight: 800;
    color: $c-white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin: 0.5rem 0;

    @include respond-to(medium) {
      margin: 0 1rem 0;
    }
  }

  &__button {
    margin: 2rem 0 0 0;

    @include respond-to(medium) {
      display: none;
    }
  }
}
