.bar {
  border-bottom: solid 0.1rem $c-grey-light-1;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to(small) {
      display: block;
    }
  }
}
