.sidebar__pages {
  &__list {
    text-align: center;
  }

  &__item {
    font-family: $f-primary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-2;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    list-style: none;
    margin: 0 0 1rem 0;

    &:last-child {
      margin: 0;
    }
  }

  &__cta {
    color: inherit;
    text-decoration: none;

    &--active {
      color: $c-primary;
    }
  }
}
