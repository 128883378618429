/* ----------------------------------------------------------------------------
 * Fonts
 * ------------------------------------------------------------------------- */

$f-primary    : 'Henderson Sans', 'Helvetica', sans-serif;
$f-secondary  : 'Playfair Display', 'Helvetica', sans-serif;
$f-tertiary	  : 'Open Sans', 'Helvetica', sans-serif;
$f-icon				: 'qub_iconfont';


/* ----------------------------------------------------------------------------
 * General variables
 * ------------------------------------------------------------------------- */

// Namespace
$ns: '' !default;

// Breakpoints
$bp-xsmall: 500px;
$bp-small: 767px;
$bp-medium: 1023px;
$bp-large: 1600px; // Default
// $bp-xlarge: everything above large
$bp-list: (
  l: (
    media: "large",
  ),
  m: (
    media: "medium",
  ),
  s: (
    media: "small",
  ),
  xs: (
    media: "xsmall",
  )
);

// page background
$page-bg: #fff;
$alt-region-bg: #F9F9F9;

// Page width
$page-min-width: 768px;
$page-max-width: 1024px;
$page-max-width-wide: 1280px;
$box-max-width: 1600px;
$page-padding: 30px;
$breakpoint: 1023px;
$breakpoint-jump-narrow: 1350px;

// Mobile
//$mobileRule: max-device-width;
$mobileRuleInverse: min-device-width;
$mobileRule: max-width;
$base-mobile-font-size: 1.5rem;
$base-mobile-line-size: 1.33;


// Grid settings
$grd-total-width: 100%;
$grd-gutter-width: 3%;
$grd-border-gutters: 1; //1 of 0
$grd-debug: 0;
$grd-debug-colors: 0;

$grda-columns: 6;
$grdb-columns: 4;

/* ----------------------------------------------------------------------------
 * Colors
 * ------------------------------------------------------------------------- */

$c-primary:       #3BB4E9;
$c-black:         #3B3B3B;
$c-white:         #FFFFFF;
$c-grey-light-1:  #F4F4F2;
$c-grey-light-2:  #EFEFEF;
$c-grey-1:        #8D8D8D;
$c-grey-2:        #545353;
$c-grey-3:        #BFBFBF;
$c-error:         #8B0004;

// mag weg
$c-secondary:     #FF0000;
$c-shadow:        #FF0000;
$c-border:        #FF0000;

/* ----------------------------------------------------------------------------
 * Spacing
 * ------------------------------------------------------------------------- */

$spacing-tiny: 1rem;
$spacing-small: 2rem;
$spacing-normal: 3rem;
$spacing-large: 6rem;
