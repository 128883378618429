.team-member {
  text-align: center;

  &__image {
    margin: 0 0 2rem 0;
    
    img {
      width: 100%;
      height: inherit;
    }
  }

  &__title {
    font-family: $f-primary;
    font-size: 1.8rem;
    font-weight: 400;
    color: $c-grey-2;
    line-height: 2.2rem;
    margin: 0 0 1rem 0;
  }

  &__info {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 60%;
    border-top: solid 0.1rem $c-grey-light-2;
    padding: 1rem 1rem 0 1rem;
    margin: 0 auto;

    &:before {
      position: absolute;
      background: $c-primary;
      content: '';
      width: 2.5rem;
      height: 0.1rem;
      top: -0.1rem;
      left: 0;
    }
  }

  &__function {
    font-family: $f-tertiary;
    font-size: 1.2rem;
    font-weight: 400;
    color: $c-grey-1;
    text-align: left;
  }

  &__email {
    color: $c-grey-1;
    text-decoration: none;
    transition: color 0.2s ease-out;

    &:hover, &:focus {
      color: $c-black;
    }

    i {
      font-size: 0.9rem;
    }
  }
}
