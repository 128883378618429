.sidebar {
  display: none;
  position: fixed;
  background: $c-white;
  z-index: 9999;
  @extend %abs-full;

  &--open {
    display: block;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    > *:not(:last-child) {
      margin: 0 0 2.5rem 0;
    }
  }

  &__close {
    position: absolute;
    background: none;
    border: none;
    top: 1.5rem;
    right: 1.5rem;

    i {
      font-size: 1.5rem;
      color: $c-black;
    }
  }

  &__logo {
    display: block;
    width: 15rem;

    img {
      width: 100%;
    }
  }
}

@import "sidebar-languages";
@import "sidebar-pages";
@import "sidebar-social";
