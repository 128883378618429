.team-placeholder {
  background: $c-grey-light-1;
  text-align: center;
  padding: 6rem 1rem;

  &__title {
    font-family: $f-primary;
    font-size: 1.8rem;
    font-weight: 400;
    color: $c-black;
    line-height: 2.2rem;
    max-width: 60%;
    margin: 0 auto 2rem auto;
  }

  &__typography {
    max-width: 75%;
    margin: 0 auto 2rem auto;
  }

  &__button {

  }
}
