.footer__disclaimer {
  opacity: .5;

  &__legal {
    font-family: $f-primary;
    font-size: 1.1rem;
    font-weight: 400;
    color: $c-white;

    @include respond-to(small) {
      text-align: center;
    }
  }
}
